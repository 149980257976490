import React from "react"
import styled from "styled-components"
import logo from "../../images/Logo.png"
import { LARGE, MEDIUM, TAB } from "../../constants/breakpoints"
import * as St from "../Styled"
import { Link } from "gatsby"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"

export const ContentAboutWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const AboutContent = styled.div`
  margin: 0 auto;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`
const Section = styled.div`
  margin: 4% 0%;
  padding: 0px 10px;
`
const Logo = styled.img`
  width: 70px;
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.aboutPage.imageSize}px;
  }
`
const ContentHeading = styled.h2`
  font-size: 20px;
  font-family: ${PROP_FONT_PRIMARY};
  margin: 2% 0%;
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.aboutPage.heading}px;
  }
`
const ContentPara = styled.p`
  font-size: 12px;
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.aboutPage.paragraph}px;
  }
`

const ContentAbout = props => {
  return (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>

      <St.ContentWrapper>
        <St.CenterContentSection>
          <AboutContent>
            <Section style={{ textAlign: "center" }}>
              <Logo src={logo} />
            </Section>
            <Section>
              <ContentHeading>
                TRANSFORMING THE WAY, YOU LISTEN TO AFRICA
              </ContentHeading>
              <ContentPara>
                Stream Africa is passionate about empowering the new wave of
                internet radio broadcasters and artists across Africa with a
                vision of providing them with the most powerful online audio
                streaming platform. We're trusted by many urban and rural radio
                stations across Africa, helping them succeed and connecting them
                with audiences around the world. Since the beginning of the
                internet, we've been able to search for anything we want and get
                it directly on the net, so why not offer the same easy access to
                radio stations and artists. Stream Africa makes it possible for
                people all over the world to get introduced to radio stations
                and artists from Africa that they normally wouldn't have heard
                about. Stream Africa allows listeners no matter where they are
                to experience Radio Stations and Artists from their local
                communities.
              </ContentPara>
            </Section>
            <Section>
              <ContentHeading>ROBUST AUDIO STREAMING TECHNOLOGY</ContentHeading>
              <ContentPara>
                Our audio streaming server combines a powerful streaming tool,
                user-friendly platform, and innovative technologies to provide
                fine-tuned and perfect wireless audio streaming service that’ll
                meet every broadcaster’s need. Whether you're a large
                established urban broadcaster or a small rural radio broadcast
                station, we will make sure your voice is heard. We utilize the
                cutting edge satellite to 5G technologies to capture audio from
                even the remotest regions of Africa to deliver quality stream
                service to the global audience.
              </ContentPara>
            </Section>
            <Section>
              <ContentHeading>
                BUILD YOUR RADIO AUDIENCE WITH STREAM AFRICA
              </ContentHeading>
              <ContentPara>
                Discover our wide range of solutions to host and broadcast your
                audio from anywhere in Africa to the world. With our
                technological infrastructure, we can ensure full connectivity,
                great online presence, and the ability to scale your audience so
                that you can monetize your radio station by making it more
                marketable to advertising brands. Whether you’re building a
                multi location or local radio network or preparing to make your
                sounds reach the global internet-based audience, Stream Africa
                can help you with the experience and streaming capabilities you
                need to deliver on your vision. Our platform is powered by
                innovative audio streaming infrastructure with 24/7 monitoring
                and is well maintained by a very competent team of developers
                and system engineers.
              </ContentPara>
            </Section>
            <Section>
              <ContentHeading>WHY STREAM AFRICA?</ContentHeading>
              <ContentPara>
                <ul>
                  <li>
                    <span>
                      Broadcast from anywhere and at any time in Africa
                    </span>
                    <p>
                      You’ll get access to our vanguard streaming tools to
                      manage and broadcast your audio without the need for
                      expensive hardware and streaming knowledge. All our
                      solutions are designed for the African Context and offer
                      “plug and forget” solutions.
                    </p>
                  </li>
                  <li>
                    <span>Effortless station management</span>
                    <p>
                      Our audio streaming server and platform makes it easy for
                      small individual and large radio stations and artists to
                      manage their online station and seamlessly connect with
                      their audience.
                    </p>
                  </li>
                  <li>
                    <span>Tools and system for improved engagement</span>
                    <p>
                      Reach listeners wherever they are with our user-friendly
                      and easily accessible streaming platform.
                    </p>
                  </li>
                </ul>
              </ContentPara>
            </Section>
            <Section>
              <ContentHeading>
                We’re Your Partner, Not Just Your Streaming Service Provider
              </ContentHeading>
              <ContentPara>
                Our dedicated support team has a wealth of knowledge to help
                you, and with our easy-to-use platform, you can rest assured
                that you’re in great hands. We’re revolutionizing the way people
                listen to the radio and stream internet-based audio. The talent
                and passion of our team at Stream Africa is bringing a global
                audience and explosive growth to African radio stations and
                artists. Choose a radio partner you can rely on, Choose Stream
                Africa. 
              </ContentPara>
            </Section>
            <Section style={{ textAlign: "center" }}>
              <ContentHeading>READY TO GET STARTED?</ContentHeading>
              <ContentPara>
                Join dozens of broadcasters who choose Stream Africa today.
              </ContentPara>
              <ContentPara>
                Contact Our Team Now: email: <br></br> support@stream-africa.com{" "}
                <br></br> <Link to="/terms">Terms & Conditions</Link>
              </ContentPara>
            </Section>
          </AboutContent>
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

export default ContentAbout
